// Core
import React, { useState, useRef, useEffect } from "react"
import { gsap } from "gsap"
import { isMobile } from "react-device-detect"

// Utils
import { loadAssets, animateSprites } from "../../utils/canvas"

// Components
import SEO from "../../components/seo"
import { drawImages } from "../../components/illustration/images"
import PageComponents from "../../components/pageComponents"

// Style
import sharedStyles from "./../pages.module.scss"

// Assets
import bgImg from "./assets/bg.jpg"
import mgImg from "./assets/mg.png"
import fgImg from "./assets/fg.png"
import squirrelImg from "./assets/squirrel.png"
import shockedSquirrelImg from "./assets/shocked-squirrel.png"
import nutImg from "./assets/nut.png"
import squirrelHandsImg from "./assets/squirrel-hands.png"
import catImg from "./assets/cat.png"
import catBushImg from "./assets/cat-bush.png"
import catOkImg from "./assets/cat-ok.png"
import catScaredImg from "./assets/cat-scared.png"
import lightCenterImg from "./assets/light-center.png"
import lightLeftImg from "./assets/light-left.png"
import lightRightImg from "./assets/light-right.png"
import tabletImg from "./assets/tablet.png"

// Sprites
import bubbleImg from "./assets/sprites/bubble.png"
import diagramImg from "./assets/sprites/diagram.png"
import droneImg from "./assets/sprites/drone.png"

// Audio
import ambient from "./assets/audio/ambient.mp3"
import vo from "./assets/audio/vo.mp3"

const pageData = {
  index: 4,
  preHeading: "Technology and Nature",
  heading: "This must be the place where nature soars and blooms.",
  content:
    "Where we replant and repair and respect. Where we capture carbon and dendro-data drives our decisions. This is where we teach about trees and marvel at mycelia. We set our satellites to survey the land’s vital signs and benchmark the blossoms and the bees. Here we count on the cascades of colour, the depth of diversity and the winding waterways, walking carefully among the saplings and the ancient woods.",
  slug: "technology-and-nature",
  nextSlug: "industrial-rewilding",
}

const TechnologyAndNature = ({ transitionStatus, entry, exit }) => {
  const [reveal, setReveal] = useState(false)

  // Asset setup
  const bg = useRef({
      parallax: 0.2,
      src: bgImg,
      el: null,
      offset: { x: -50, y: 0 },
    }),
    mg = useRef({
      parallax: 0.5,
      src: mgImg,
      el: null,
      offset: { x: -80, y: 0 },
    }),
    fg = useRef({
      parallax: 1,
      src: fgImg,
      el: null,
      offset: { x: -80, y: 661 },
    }),
    bubble1 = useRef({
      parallax: 0.25,
      src: bubbleImg,
      el: null,
      offset: { x: 2990, y: 700 },
      size: { w: 45, h: 130 },
      currentSprite: 1,
      spriteFrames: 24,
      slowFactor: 4,
      loop: true,
    }),
    bubble2 = useRef({
      parallax: 0.25,
      src: bubbleImg,
      el: null,
      offset: { x: 2995, y: 685 },
      size: { w: 45, h: 130 },
      currentSprite: 7,
      spriteFrames: 24,
      slowFactor: 4,
      loop: true,
    }),
    bubble3 = useRef({
      parallax: 0.25,
      src: bubbleImg,
      el: null,
      offset: { x: 2980, y: 670 },
      size: { w: 45, h: 130 },
      currentSprite: 14,
      spriteFrames: 24,
      slowFactor: 4,
      loop: true,
    }),
    squirrel = useRef({
      parallax: 0.5,
      src: squirrelImg,
      el: null,
      offset: { x: 2108, y: 200 },
    }),
    shockedSquirrel = useRef({
      parallax: 0.5,
      src: shockedSquirrelImg,
      el: null,
      offset: { x: 2251, y: 268 },
      hidden: true,
    }),
    nut = useRef({
      parallax: 0.5,
      src: nutImg,
      el: null,
      offset: { x: 2265, y: 305, r: 0 },
      rotationPoint: { x: 24, y: 28 },
    }),
    squirrelHands = useRef({
      parallax: 0.5,
      src: squirrelHandsImg,
      el: null,
      offset: { x: 2237, y: 330 },
    }),
    cat = useRef({
      parallax: 0.5,
      src: catImg,
      el: null,
      offset: { x: 663, y: 1359 },
    }),
    catBush = useRef({
      parallax: 0.5,
      src: catBushImg,
      el: null,
      offset: { x: 540, y: 1380 },
    }),
    catOk = useRef({
      parallax: 0.5,
      src: catOkImg,
      el: null,
      offset: { x: 892, y: 1356 },
    }),
    catScared = useRef({
      parallax: 0.5,
      src: catScaredImg,
      el: null,
      offset: { x: 892, y: 1356 },
      hidden: true,
    }),
    lightCenter1 = useRef({
      parallax: 0.2,
      src: lightCenterImg,
      el: null,
      offset: { x: 343, y: 391 },
    }),
    lightCenter2 = useRef({
      parallax: 0.5,
      src: lightCenterImg,
      el: null,
      offset: { x: 2197, y: 274 },
    }),
    lightCenter3 = useRef({
      parallax: 0.5,
      src: lightCenterImg,
      el: null,
      offset: { x: 1880, y: 737 },
    }),
    lightCenter4 = useRef({
      parallax: 0.5,
      src: lightCenterImg,
      el: null,
      offset: { x: 2006, y: 1119 },
    }),
    lightLeft1 = useRef({
      parallax: 0.5,
      src: lightLeftImg,
      el: null,
      offset: { x: 629, y: 1240 },
    }),
    lightLeft2 = useRef({
      parallax: 0.5,
      src: lightLeftImg,
      el: null,
      offset: { x: 1348, y: 965, r: -10 },
      rotationPoint: { x: 3, y: 5 },
    }),
    lightLeft3 = useRef({
      parallax: 0.5,
      src: lightLeftImg,
      el: null,
      offset: { x: 2814, y: 1272, r: 30 },
      rotationPoint: { x: 3, y: 5 },
    }),
    lightLeft4 = useRef({
      parallax: 0.5,
      src: lightLeftImg,
      el: null,
      offset: { x: 3160, y: 1420 },
    }),
    lightRight1 = useRef({
      parallax: 0.5,
      src: lightRightImg,
      el: null,
      offset: { x: 1068, y: 1378, r: -20 },
      rotationPoint: { x: 3, y: 5 },
    }),
    lightRight2 = useRef({
      parallax: 0.5,
      src: lightRightImg,
      el: null,
      offset: { x: 2392, y: 940, r: -8 },
      rotationPoint: { x: 3, y: 5 },
    }),
    lightRight3 = useRef({
      parallax: 0.5,
      src: lightRightImg,
      el: null,
      offset: { x: 2594, y: 1423, r: -15 },
      rotationPoint: { x: 3, y: 5 },
    }),
    drone = useRef({
      parallax: 0.7,
      src: droneImg,
      el: null,
      offset: { x: 3600, y: -200, r: 1 },
      size: { w: 400, h: 266 },
      rotationPoint: { x: 200, y: 133 },
      currentSprite: 1,
      spriteFrames: 40,
      slowFactor: 1,
      loop: true,
    }),
    tablet = useRef({
      parallax: 0.9,
      src: tabletImg,
      el: null,
      offset: { x: 407, y: 1663 },
    }),
    diagram = useRef({
      parallax: 0.9,
      src: diagramImg,
      el: null,
      offset: { x: 528, y: 1763 },
      size: { w: 400, h: 240 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 20,
      paused: true,
      loop: false,
    })

  // All assets in this scene (inc. sprites)
  const assets = useRef([
    bg.current,
    mg.current,
    squirrel.current,
    shockedSquirrel.current,
    nut.current,
    squirrelHands.current,
    cat.current,
    bubble1.current,
    bubble2.current,
    bubble3.current,
    catBush.current,
    catOk.current,
    catScared.current,
    lightCenter1.current,
    lightCenter2.current,
    lightCenter3.current,
    lightCenter4.current,
    lightLeft1.current,
    lightLeft2.current,
    lightLeft3.current,
    lightLeft4.current,
    lightRight1.current,
    lightRight2.current,
    lightRight3.current,
    drone.current,
    tablet.current,
    diagram.current,
    fg.current,
  ])

  // All sprite assets in this scene
  const sprites = useRef([
    bubble1.current,
    bubble2.current,
    bubble3.current,
    diagram.current,
    drone.current,
  ])

  // Lights
  const lights = useRef([
      lightCenter1.current,
      lightCenter2.current,
      lightCenter3.current,
      lightCenter4.current,
      lightLeft1.current,
      lightLeft2.current,
      lightLeft3.current,
      lightLeft4.current,
      lightRight1.current,
      lightRight2.current,
      lightRight3.current,
    ]),
    lightCount = lights.current.length

  // Set the mouse entry point if there is one passed in
  const mousePoint = {
    x: entry.state.mousePos ? entry.state.mousePos.x : 0,
    y: entry.state.mousePos ? entry.state.mousePos.y : 0,
  }

  // The point to focus on when the page enters
  const focusPoint = {
    x: 2233,
    y: 321,
  }

  // Anything that should run on each canvas draw
  // (Should always end with drawImages)
  const draw = (cSize, ctx, cursorPos, frameCount) => {
    animateSprites(sprites.current, frameCount)
    drawImages(assets.current, cSize, ctx, cursorPos)
  }

  const lightOffInterval = useRef(null),
    lightOnTimeout = useRef(null)

  const lightOn = randomLight => {
    lightOnTimeout.current = setTimeout(() => {
      lights.current[randomLight].hidden = false
    }, 100)
  }

  const lightOff = () => {
    let randomLight = Math.floor(Math.random() * (lightCount - 1))
    lights.current[randomLight].hidden = true
    lightOn(randomLight)
  }

  const shockCat = direction => {
    if (direction === "in") {
      gsap.to(cat.current.offset, {
        y: 1259,
        duration: 0.2,
        ease: "power3.inOut",
      })
      gsap.fromTo(
        catBush.current.offset,
        { x: 540 },
        { x: 530, duration: 0.05, ease: "power1.inOut", repeat: 6, yoyo: true }
      )
      catOk.current.hidden = true
      catScared.current.hidden = false
    } else {
      gsap.to(cat.current.offset, {
        y: 1359,
        duration: 0.2,
        ease: "power3.inOut",
      })
      catOk.current.hidden = false
      catScared.current.hidden = true
    }
  }

  const dropNut = direction => {
    if (direction === "in" && nut.current.offset.y === 305) {
      shockedSquirrel.current.hidden = false
      gsap.to(nut.current.offset, { y: 945, duration: 1, ease: "power2.in" })
      gsap.to(nut.current.offset, {
        x: 2565,
        duration: 1,
        ease: "power1.out",
        delay: 1,
      })
      gsap.to(nut.current.offset, {
        r: 1160,
        duration: 2,
        ease: "power1.out",
        delay: 1,
      })
      gsap.to(nut.current.offset, {
        y: 1883,
        duration: 2,
        ease: "bounce.out",
        delay: 1,
      })
    }
  }

  // Initial animations
  const startingAnimations = () => {
    gsap.to(drone.current.offset, {
      y: 280,
      duration: 10,
      ease: "back.out(1.3)",
      delay: 3,
    })
    gsap.to(drone.current.offset, {
      x: 2838,
      duration: 20,
      ease: "back.out(1.3)",
      delay: 3,
    })
    gsap.fromTo(
      drone.current.offset,
      { r: 40 },
      { r: -20, duration: 8, ease: "power2.inOut", repeat: -1, yoyo: true }
    )
  }

  const showDiagram = () => {
    diagram.current.paused = false
  }

  // Animate in
  useEffect(() => {
    lightOffInterval.current = setInterval(lightOff, 200)
    let revealTimeout = setTimeout(() => {
      setReveal(true)
      startingAnimations()

      if (isMobile) dropNut("in")
    }, 1000)
    let showDiagramTimeout = setTimeout(showDiagram, 10000)

    return () => {
      if (revealTimeout) clearInterval(revealTimeout)
      if (showDiagramTimeout) clearInterval(showDiagramTimeout)
      if (lightOffInterval.current) clearInterval(lightOffInterval.current)
      if (lightOnTimeout.current) clearTimeout(lightOnTimeout.current)
    }
  }, [])

  // Load images
  useEffect(() => {
    loadAssets(assets.current)
  }, [])

  return (
    <div>
      <SEO title={pageData.heading} />
      <div className={sharedStyles.page}>
        <PageComponents
          index={pageData.index}
          preHeading={pageData.preHeading}
          heading={pageData.heading}
          content={pageData.content}
          mousePoint={mousePoint}
          focusPoint={focusPoint}
          playing={reveal}
          draw={draw}
          fromPage={pageData.slug}
          to={`/${pageData.nextSlug}`}
          ambient={ambient}
          vo={vo}
          interactiveElements={[
            {
              event: shockCat,
              x: 650,
              y: 1260,
              w: 545,
              h: 430,
            },
            {
              event: dropNut,
              x: 2090,
              y: 100,
              w: 260,
              h: 240,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default TechnologyAndNature
